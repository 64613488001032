<template>
  <!-- 广告管理 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('gjsz_guanGaoGuanLi:add')"
        class="itemBtn btnColor"
        @click="apply"
        >新建</el-button
      >

      <el-button
        v-if="hasPermiss('gjsz_guanGaoGuanLi:update')"
        class="itemBtn btnColor"
        @click="modifybut"
        >修改</el-button
      >

      <el-button
        v-if="hasPermiss('gjsz_guanGaoGuanLi:del')"
        class="itemBtn btnColor"
        @click="CancelPayment"
        >删除</el-button
      >

      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件 -->
    <!-- <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">公告标题:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                filterable
                clearable
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">公告类型:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                filterable
                clearable
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp1">
            <div class="singleinpleft">新建时间:</div>
            <div class="singleinpright timebox">
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="timebut"
                v-model="time1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="item_left" v-if="hasPermiss('gjsz_guanGaoGuanLi:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form> -->

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        show-summary
        :summary-method="getSummaries"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            sortable
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>

    <!-- //新建修改 -->
    <div>
      <el-dialog
        :title="newDialogTit"
        :visible.sync="NewWarehouse"
        :before-close="WarehouseClose"
      >
        <div class="newForm">
          <el-form
            label-width="120px"
            :rules="rules"
            status-icon
            :model="advertisement"
            ref="advertisement"
          >
            <!-- <div class="queryItem1" v-if="pageType == 'modify'">
              <el-form-item label="公告编号:">
                <el-input :disabled="NumberStatus"></el-input>
              </el-form-item>
            </div> -->
            <div class="queryItem1">
              <el-form-item label="公告标题:" prop="title">
                <el-input v-model="advertisement.title"></el-input>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="排序" prop="sort">
                <el-input-number
                  v-model="advertisement.sort"
                  controls-position="right"
                  :min="1"
                  :max="100000"
                ></el-input-number>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="备注:">
                <el-input
                  v-model="advertisement.comment"
                  type="textarea"
                  :rows="1"
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="文本类容:">
                <div id="eidtContent" v-if="NewWarehouse"></div>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="footerbox">
          <el-button class="seniorbut" @click="PaymentConfirmation"
            >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 会员信息弹窗 -->
    <div></div>
    <!-- 打包批次号-目的地-渠道代理获取申请打包单信息 -->
    <div></div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import tool from "@/assets/js/tool";

import wangeditor from "wangeditor";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    sorttable,
    draggable,
    paging,
  },
  data() {
    return {
      time1: "",
      time2: "",
      FormData: {
        aaa: "",
      },
      value: "",
      queryData: {},

      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "创建人",
          field: "createUserName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "标题",
          field: "title",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },

        {
          name: "公告文本",
          field: "content",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
        },
        {
          name: "公告状态",
          width: "120",
          field: "",
          sort: 3,
          isShow: true,
          isTotal: false,
        },
        {
          name: "公告排序",
          field: "sort",
          sort: 3,
          width: "120",
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "160",
          sort: 4,
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建人",
          field: "",
          sort: 5,
          width: "120",
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "AdvertisingManagement", //页面标识
      selectTableData: [], //选中的数据
      //===============
      pageNum_member: 1, //页码
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      cangkuList: [], //仓库下拉列表
      /*       yunshuList: [], //运输方式列表
      shengheList: [], //审核状态列表
      qudaoList: [], //渠道列表
      qudaodailiList: [], //渠道代理列表 */
      NewWarehouse: false, //新建修改仓库弹窗
      newDialogTit: "",
      pageType: "", //修改删除状态
      rules: {
        content: [
          { required: true, message: "请输入文本内容", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入公告标题", trigger: "blur" }],
        sort: [{ required: true, message: "请选择排序值", trigger: "blur" }],
      },
      advertisement: {
        content: "", //内容富文本
        sort: "", //排序
        title: "", //广告通知消息标题
        comment: "", //备注
        id: "", //广告通知消息id
      },
      NumberStatus: false, //编号状态(是否禁用)

      editor: {}, //富文本编辑对象
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    this.endtime = tools.getCurrentDate(); //今天的时间;
    // this.endtime = tools.getFlexDate(1); //今天+1的时间;
    this.sarttime = tools.getFlexDate(-3); //三天前
    this.times = [this.sarttime, this.endtime];
  },
  mounted() {
    const that = this;
    this.getList();
    this.getData();
    this.getStatusValList("finance_in_record_v1.approve_status") || []; //审核状态列表
  },
  watch: {
    NewWarehouse: function (newVal, oldVal) {
      if (!newVal) {
        this.advertisement = {
          content: "", //内容富文本
          sort: "", //排序
          title: "", //广告通知消息标题
          comment: "", //备注
          id: "", //广告通知消息id
        };

        // this.editor.txt = "";
      }
    },
  },
  methods: {
    initLangeuage() {
      this.editor = {};
      this.editor = new wangeditor("#eidtContent");

      this.editor.config.height = 300; //富文本高度

      // 自定义处理图片的上传逻辑
      this.editor.config.customUploadImg = (files, insert) => {
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("fileName", files[0].name || ""); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
        let signArr = [
          {
            key: "fileName",
            val: files[0].name || "",
          },
        ];

        let sign = this.Tools.getMD5Sign(signArr);
        formData.append("sign", sign);
        this.$showLoading();
        Api.uploadFile(formData)
          .then((res) => {
            this.$hideLoading();
            // 获取服务器返回的图片链接
            const imageUrl = res.data.result.url || "";
            let imgurl = imageUrl;
            let imgList = imageUrl.split("?") || [];
            if (imgList.length > 0) {
              imgurl = imgList[0];
            }
            // 将图片链接插入到编辑器中
            insert(imgurl);
          })
          .catch(() => {
            this.$hideLoading();
          });

        // 发送图片文件到服务器
        // 可使用 fetch、axios、XMLHttpRequest 等方式发送请求

        // // 获取服务器返回的图片链接
        // const imageUrl =
        //   "https://img1.baidu.com/it/u=3663508195,2907650417&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500";

        // // 将图片链接插入到编辑器中
        // insert(imageUrl);
      };
      this.editor.create();
    },

    //新建时间
    timebut(e) {
      console.log(e[0] + " " + "00:00:00");
    },
    //仓库弹窗关闭
    WarehouseClose() {
      this.$refs.advertisement.resetFields();
      this.NewWarehouse = false;
      this.advertisement = {
        content: "", //内容富文本
        sort: "", //排序
        title: "", //广告通知消息标题
        comment: "", //备注
        id: "", //广告通知消息id
      };
      this.editor = {};
    },
    //新建仓库
    apply() {
      this.NumberStatus = false;
      this.pageType = "add";
      this.NewWarehouse = true;
      this.newDialogTit = "新建";
      let that = this;

      setTimeout(() => {
        that.initLangeuage();
      }, 200);
    },
    //修改
    modifybut() {
      let that = this;
      this.NumberStatus = true;
      this.pageType = "modify";
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.newDialogTit = "修改";
        this.NewWarehouse = true;

        let obj = selectTableData[0];
        console.log("obj", obj);
        that.advertisement = {
          content: obj.content, //内容富文本
          sort: obj.sort, //排序
          title: obj.title, //广告通知消息标题
          comment: "", //备注
          id: obj.advertId, //广告通知消息id
        };

        this.$nextTick(() => {
          that.initLangeuage();

          setTimeout(() => {
            that.editor.txt.html(obj.content);
          }, 200);
        });
      }
    },
    //新建修改保存
    PaymentConfirmation() {
      this.$refs.advertisement.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          //新建
          let _this = this;
          let advertisement = _this.advertisement;
          let param = {
            content: _this.editor.txt.html(),
            sort: advertisement.sort,
            title: advertisement.title,
            comment: advertisement.comment,
            id: advertisement.id,
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          if (this.pageType == "add") {
            if (this.fileName != "") {
              Api.addAdverInfos(param).then((res) => {
                if (res.data.status == "success") {
                  this.$message.success(res.data.message || "操作成功");
                  this.NewWarehouse = false;
                  _this.getData();
                }
              });
            } else {
              console.log("修改");
            }
          }
          //修改
          else {
            Api.modifyAdverInfos(param).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                this.NewWarehouse = false;
                _this.getData();
              }
            });
          }
        }
      });
    },
    //删除
    CancelPayment() {
      let _this = this;
      let selectTableData = this.selectTableData;
      if (selectTableData.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        let advertId = selectTableData[0].advertId;
        let parm = {
          advertId: advertId,
        };
        let sign = tool.getSign(parm);
        parm.sign = sign;
        this.$confirm("是否确认删除", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        }).then(() => {
          Api.delAdvert(parm).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              _this.getData();
            }
          });
        });
      }
    },
    //获取下拉列表
    getList() {
      //获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 服务类型:==========> item_category_v1.service_status
      // 费项类型:==========> item_service_v1.direction_status
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      } else {
        this.screen();
      }
    },
    //全屏模式
    screen() {
      // if (screenfull.isEnabled) {
      //     screenfull.toggle(this.$refs.tableWrapper);
      // }
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault();
        event.returnValue = false;
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height;
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen;

      if (isFull === undefined) {
        this.isFullscreen = winFlag;
      } else {
        this.isFullscreen = winFlag || isFull;
      }
      //console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true;
        this.TableStyle = false;

        //console.log(this.tableHeigth)
      } else {
        this.fullscreen = false;
        this.TableStyle = true;
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = downloadurl;
        a.target = "_blank";
        a.setAttribute("download", "综合查询.xlsx"); //指定下载后的文件名，防跳转
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "公告管理列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10; //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      Api.getAdvertList(formData).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      //console.log(this.queryData);
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {};
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.imgbox {
  width: 100%;
  margin-left: 12%;
  box-sizing: border-box;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  // margin-left: 80px;
  padding: 10px 20px;
  box-sizing: border-box;
  .queryItem1 {
    width: 100%;
    margin-top: 20px;
  }
}
.singleinp1 {
  min-width: 392px;
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.SingleChoicebox {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  box-sizing: border-box;
}
.el-input-number {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
</style>
